<template>
  <v-layout row wrap>
    <v-container>
      <v-row>
        <v-col :cols="12" :md="rightDrawer ? 9 : 12">
          <v-breadcrumbs :items="breadCrumbItems" />
          <slot />
        </v-col>
        <v-col :cols="12" :md="3" v-if="rightDrawer">
          <slot name="drawer" />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['rightDrawer', 'extended', 'page-description'],
  computed: {
    ...mapGetters([
      'hasPendingAxiosCall',
      'translate',
      // 'layoutAppBarSlot',
      'activeMarket',
      'markets'
    ]),
    breadCrumbItems () {
      return this.pageDescription && this.pageDescription.getBreadcrumbItems()
    }
  },
  mounted () {
    this.registerLayoutPage({layoutPage: this, enable: true})
  },
  beforeDestroy () {
    this.registerLayoutPage({layoutPage: this, enable: false})
  },
  methods: {
    ...mapActions(['registerLayoutPage', 'layoutNavToggle']),
    getToolbarItemsSlot () {
      return this.$scopedSlots['toolbar-items']
    }
  }
}
</script>
