const LazyAssetsMixin = {
  methods: {
    loadAsset ({element, selector, props}) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(selector)) {
          return resolve()
        }
        const el = document.createElement(element)
        Object.assign(el, props)
        el.addEventListener('load', resolve)
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        document.head.appendChild(el)
      })

    },
    loadScripts (srcs) {
      return srcs.reduce((p, src) => p.then(() => this.loadScript(src)), Promise.resolve())
    },
    loadStyles (hrefs) {
      return hrefs.reduce((p, href) => p.then(() => this.loadStyle(href)), Promise.resolve())
    },

    loadScript (src) {
      return this.loadAsset({
        element: 'script',
        selector: `script[src="${src}"]`,
        props: {
          type: 'text/javascript',
          async: true,
          src
        }
      })
    },
    loadStyle (href) {
      return this.loadAsset({
        element: 'link',
        selector: `link[href="${href}"]`,
        props: {
          rel: 'stylesheet',
          async: true,
          href
        }
      })
    }
  }
}
export default LazyAssetsMixin
