<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('New stored query')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        text
        :loading="loading"
        :disabled="!valid"
        @click="save"
        >
        <v-icon>save</v-icon> {{translate('Save')}}
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-form v-model="valid">
          <v-toolbar flat>
            <v-spacer />
            <v-btn text v-for="(link, i) in previewLinks" target="_blank" :href="link.url" :key="i">
              {{link.label}}&nbsp;<v-icon small>fa-external-link-alt</v-icon>
            </v-btn>
          </v-toolbar>
          <v-text-field
            :label="translate('Id')"
            :value="storedQueryId"
            :loading="loading"
            :disabled="true"
            />
          <v-text-field
            :label="translate('Description')"
            v-model="query.description"
            :enabled="false"
            :loading="loading"/>
          <v-layout row mx-0>
            <v-flex v-for="role in roleNames" :key="role">
              <v-checkbox  v-model="query.roles[role]" :label="role" :loading="loading"/>
            </v-flex>
            <v-spacer />
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <v-layout column>
      <v-flex>
        <div ref="playground" class="xs12" style="height: 100%; min-height: 50vh"></div>
      </v-flex>
    </v-layout>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import LazyAssetsMixin from '@/components/lazy-assets-mixin.js'
import axios from 'axios'

export default {
  props: ['storedQueryId'],
  data: () => ({
    mutations: 1,
    valid: true,
    assetsPromise: null,
    domPromise: null
  }),
  mixins: [LazyAssetsMixin],
  components: {
    LayoutPage
  },
//  async mounted () {
//    this.$nextTick(() => this.initialize())
//  },
  computed: {
    ...mapGetters(['api', 'translate', 'validationRules']),
    roleNames () {
      return this.details.roles
    },
    loading () { return this.details.loading },
    query () { return this.details.query },

    previewLinks () {
      const enc = encodeURIComponent
      return ['JSON', 'XML']
        .map(format => ({
          label: format,
          url: `/api/v1/query/${format.toLowerCase()}/${enc(this.storedQueryId)}`
        }))
    }
  },
  asyncComputed: {
    details: {
      async get () {
        let query = this.mutations && await this.api.storedQueries.getStoredQuery(this.storedQueryId)
        let roles = this.mutations && await this.api.roles.getRoles(this.storedQueryId)
        return {
          roles,
          query: {
            gql: {query: '', variables: {}},
            roles: {},
            ...query
          },
          loading: false
        }
      },
      default: {
        loading: true,
        roles: {},
        query: {
          gql: {
            query: '',
            variables: {}
          }
        }
      }
    }
  },
  watch: {
    async details () {
      this.$nextTick(() => this.bindGraphQLUI())
    }
  },
  methods: {
    async bindGraphQLUI () {
      this.assetsPromise = this.assetsPromise || this.loadGraphQLAssets()

      await this.assetsPromise

      this.domPromise = this.domPromise || Promise.resolve(true)

      this.domPromise = this.domPromise.then(() => this.renderGraphQLDom())
    },
    async loadGraphQLAssets () {
      // Guide to hosting graphiQL:
      // https://github.com/graphql/graphiql/blob/master/packages/graphiql-examples/cdn/index.html
      const scripts = [
        '//cdn.jsdelivr.net/es6-promise/4.0.5/es6-promise.auto.min.js',
        '//cdn.jsdelivr.net/react/15.4.2/react.min.js',
        '//cdn.jsdelivr.net/react/15.4.2/react-dom.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/graphiql/0.14.2/graphiql.min.js',
      ]
      const styles = ['https://cdnjs.cloudflare.com/ajax/libs/graphiql/0.14.2/graphiql.min.css']
      await this.loadStyles(styles)
      await this.loadScripts(scripts)
    },
    async renderGraphQLDom () {
      let self = this
      async function fetcher (graphQLParams) {
        let {data} = await axios.post('/api/v1/graphql', {
          ...graphQLParams,
          transform: graphQLParams.operationName === 'IntrospectionQuery' ? undefined : self.transformScript
        })
        return data
      }
      const tryParseJson = s => {
        try {
          return JSON.parse(s)
        } catch (err) {
          return null
        }
      }
      return window.ReactDOM.render(
        window.React.createElement(window.GraphiQL,
          {
            fetcher,
            query: this.query.gql.query || 'query MyQuery {}',
            variables: JSON.stringify(this.query.gql.variables || {}),

            onEditQuery (q) { self.query.gql.query = q },
            onEditVariables (v) { self.query.gql.variables = tryParseJson(v) ||  self.query.gql.variables}
            // query: '{articles {articleNo}}'
          }
        ),
        this.$refs.playground)
    },
    async save () {
      await this.api.storedQueries.updateStoredQuery(this.details.query)
      this.mutations = this.mutations + 1
    }
  }
}
</script>
<style>
.editorWrap {
  min-height: 50vh;
}
</style>
